@import '../../styles/constants.scss';

.toggle-input {
  display: inline-block;
}

.editor {
  box-sizing: border-box;
  border: 1px solid $gray-800;
  cursor: text;
  padding: 8px;
  border-radius: 2px;
  background: $gray-50;

  &.focus {
    border-color: $primary-500;
  }

  &.read-only {
    border: none;
    background: transparent;
    color: inherit;
    padding: 0;
  }
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

@import "./styles/constants";

html {
  font-size: 100%;
}

body {
  overflow: hidden;
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  height: 100%;
  width: 100%;
  position: fixed;
}

.app-root {
  height: 100%;
}

a {
  color: $primary-500;
  text-decoration: none;
}

li {
  list-style: none; }

h1, h2, h3, h4, h5, h6 {
  color: $primary-500;
  margin: 15px 0;
  font-family: 'RobotoSlab', serif !important; }

.header-search {
  background: none;
  border: none;
  border-bottom: 1px solid $gray-A100;
  outline: none;
  color: $gray-A100;
  font-size: 20px; }

@font-face {
  font-family: 'icomoon';
  src: url("./assets/fonts/icomoon.eot?4kqh67");
  src: url("./assets/fonts/icomoon.eot?4kqh67#iefix") format("embedded-opentype"), url("./assets/fonts/icomoon.ttf?4kqh67") format("truetype"), url("./assets/fonts/icomoon.woff?4kqh67") format("woff"), url("./assets/fonts/icomoon.svg?4kqh67#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'RobotoSlab';
  src: url("./assets/fonts/RobotoSlab-Regular.ttf");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-eventbrite:before {
  content: "\e913";
  color: #f05537;
}
.icon-google:before {
  content: "\e914";
  color: #4285f4;
}
.icon-meetup:before {
  content: "\e915";
  color: #ed1c40;
}
.icon-doormouse-key:before {
  content: "\e912";
}
.icon-upload:before {
  content: "\e911";
}
.icon-eye-slash:before {
  content: "\e90f";
}
.icon-eye:before {
  content: "\e910";
}
.icon-address-card:before {
  content: "\e90e";
}
.icon-doors:before {
  content: "\e90d";
}
.icon-clone:before {
  content: "\e906";
}
.icon-map:before {
  content: "\e908";
}
.icon-bullseye:before {
  content: "\e909";
}
.icon-globe:before {
  content: "\e90a";
}
.icon-users:before {
  content: "\e90b";
}
.icon-calendar:before {
  content: "\e90c";
}
.icon-sound:before {
  content: "\e907";
}
.icon-spotlight:before {
  content: "\e900";
}
.icon-incognito:before {
  content: "\e901";
}
.icon-handshake-o:before {
  content: "\e902";
}
.icon-invite-friend:before {
  content: "\e903";
}
.icon-doormouse:before {
  content: "\e905";
}

.MuiTypography-colorPrimary {
  color: $primary-500 !important;
}

.btn {
  outline: none !important; }

.view-loading i {
  position: absolute;
  top: 100px;
  color: $primary-500;
  left: calc(50% - 90px);
  font-size: 200px; }

.indent-block {
  display: table-row;
  width: 100%; }
.indent-block .indent-block-left {
  width: 40px;
  display: table-cell; }
.indent-block .indent-block-right {
  display: table-cell; }

.icon-backing {
  height: 150px;
  overflow: hidden;
  position: relative;
  border-radius: 8px; }
.icon-backing .icon-back {
  font-size: 300px;
  position: absolute;
  z-index: -1;
  color: $gray-100;
  overflow: hidden;
  padding: 0 0 0 50px;
  width: 100%;
  height: 150px; }
.icon-backing .icon-back.calendar {
  font-size: initial;
  padding: 0; }
.icon-backing .icon-back span {
  -ms-transform: rotate(-30deg);
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg); }
.icon-backing .text-front {
  z-index: 5;
  height: 100%;
  padding: 15px;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.15) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.15) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.15) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
  /* IE6-9 */ }
.icon-backing .text-front.big-gradient {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.15) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.15) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.15) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
  /* IE6-9 */ }

.full-width-scroll {
  width: 100%;
  overflow: auto !important; }

.rotate-45 {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }

.rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg); }

.rotate-135 {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg); }

.rotate-180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg); }

.rotate-225 {
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  transform: rotate(225deg); }

.rotate-270 {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg); }

.rotate-315 {
  -webkit-transform: rotate(315deg);
  -moz-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  -o-transform: rotate(315deg);
  transform: rotate(315deg); }

.totals-label {
  position: fixed;
  z-index: 1200;
  top: 23px;
  left: calc(100vw / 2 - 145px);
  color: $primary-500;
  font-weight: bold;
  font-size: 16px; }

.hidden-disabled input, .hidden-disabled select {
  color: $standard; }

.WUT .rbc-event {
  background-color: $gray-200 !important;
  color: $standard; }

.MAYBE .rbc-event {
  background-color: $warning-500 !important; }

.YES .rbc-event {
  background-color: $primary-500 !important; }

.follow-us {
  position: relative;
  float: right; }

table tr.highlight {
  background-color: $primary-100; }

table tr td.highlight {
  border: 2px solid $error-500 !important }

tbody tr:nth-child(odd) {
  background-color: $gray-100; }

.categories-table tbody, .categories-table thead {
  display: block; }

.categories-table tbody {
  overflow: auto; }

.categories-table thead tr th {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px; }

.categories-table tbody tr td {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  border-left: 1px solid $gray-300;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px; }

.categories-table tfoot {
  display: block;
  position: fixed;
  width: 4500px;
  bottom: 0;
  left: 0;
  background-color: $gray-A100;
  border-top: 1px solid $gray-300; }

.categories-table.columns-collapsed tbody tr td {
  width: 50px;
  min-width: 50px;
  max-width: 50px; }

.leaflet-container {
  height: 100%;
  width: 100%;
  margin: 0 auto; }

.leaflet-control-container .leaflet-left {
  right: 10px;
  left: initial; }

.draftJsToolbar__buttonWrapper__1Dmqh {
  display: inline-block; }

.draftJsToolbar__button__qi1gf {
  background: $gray-50;
  color: $gray-800;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px; }

.draftJsToolbar__button__qi1gf svg {
  fill: $gray-800; }

.draftJsToolbar__button__qi1gf:hover, .draftJsToolbar__button__qi1gf:focus {
  background: $gray-50;
  outline: 0;
  /* reset for :focus */ }

.draftJsToolbar__active__3qcpF {
  background: $gray-100;
  color: $standard; }

.draftJsToolbar__active__3qcpF svg {
  fill: $standard; }

.draftJsToolbar__separator__3U7qt {
  display: inline-block;
  border-right: 1px solid $gray-200;
  height: 24px;
  margin: 0 0.5em; }

.draftJsToolbar__toolbar__dNtBH {
  left: 50%;
  -webkit-transform: translate(-50%) scale(0);
  transform: translate(-50%) scale(0);
  position: absolute;
  border: 1px solid $gray-200;
  background: $gray-A100;
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 $gray-200;
  z-index: 2;
  box-sizing: border-box; }

.draftJsToolbar__toolbar__dNtBH:after, .draftJsToolbar__toolbar__dNtBH:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none; }

.draftJsToolbar__toolbar__dNtBH:after {
  border-color: $gray-50;
  border-top-color: $gray-A100;
  border-width: 4px;
  margin-left: -4px; }

.draftJsToolbar__toolbar__dNtBH:before {
  border-color: $gray-50;
  border-top-color: $gray-200;
  border-width: 6px;
  margin-left: -6px; }

.with-bottom-nav {
  padding-bottom: 60px; }

.react-images__dialog {
  height: 100%; }

.mapboxgl-popup-content {
  background-color: $error-500;
  border-radius: 30px;
  color: white;
  min-width: 50px;
  padding: 10px 20px;
}

.mapboxgl-popup-content .editor {
  margin-bottom: 0;
  background-color: transparent;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: $error-500 !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: $error-500 !important;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-left-color: $error-500 !important;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-right-color: $error-500 !important;
}

.mapboxgl-popup-close-button {
  color: $gray-A100;
}